import React, { Component } from 'react'
import Link from '../utils/link'

import NewsletterForm from './newsletter'

import Logo from '../assets/images/logo.svg'
import LinkedIn from '../assets/images/linkedin-white.svg'
import Email from '../assets/images/email.svg'

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  onScroll = () => {
    this.setState({
      scrolled: window.scrollY > 1,
    })
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  render() {

    let { offCanvas, scrolled } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let headerClass = 'header'
    if (scrolled) headerClass += ' header--scrolled'

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner'>
            <Link to='/' title='Site Name' className='header__logo' {...props}>
              <img src={Logo} alt='Bio101' />
            </Link>
            <nav className='header__nav'>
              <ul>
                <li><Link to='/services' {...props}>Our Services</Link></li>
                <li><Link to='/experience' {...props}>Our Experience</Link></li>
                <li><Link to='/people' {...props}>Our People</Link></li>
                <li><Link to='/contact' {...props}>Contact Us</Link></li>
              </ul>
            </nav>
            <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
              <span className='lines'></span>
            </button>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
                <li><Link to='/services' {...props}>Our Services</Link></li>
                <li><Link to='/experience' {...props}>Our Experience</Link></li>
                <li><Link to='/people' {...props}>Our People</Link></li>
                <li><Link to='/contact' {...props}>Contact Us</Link></li>
              </ul>
            </nav>
            <ul className='off-canvas__social'>
              <li><Link to='//'><img src={LinkedIn} alt='Bio101 - LinkedIn' /></Link></li>
              <li><Link to='//'><img src={Email} alt='Bio101 - Email' /></Link></li>
            </ul>
            <NewsletterForm />
            <div className='off-canvas__contact'>
              <h4><Link to='tel:+6198767890'>+61 9876 7890</Link></h4>
              <h4><Link to='mailto:info@bio101.com'>info@bio101.com</Link></h4>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Header
